import { useEditor, EditorContent } from '@tiptap/react';
import { Color } from '@tiptap/extension-color';
import Paragraph from '@tiptap/extension-paragraph';
import ListItem from '@tiptap/extension-list-item';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import StarterKit from '@tiptap/starter-kit';
import React, { useState } from 'react';

import { Tooltip } from '@mui/material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import TitleIcon from '@mui/icons-material/Title';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import LinkIcon from '@mui/icons-material/Link';

import { templateList, signatureList, subjectSetter } from 'src/sections/@dashboard/status/statusView/statusHelper';
import Dropdown from './Dropdown';
import { getTemplate } from 'src/sections/@dashboard/status/statusView/templates';

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="menuBar">
      <Tooltip placement="top" title="Bold">
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          // disabled={!editor.can().chain().focus().toggleBold().run()}
          className={`menuButton ${editor.isActive('bold') ? 'is-active' : ''}`}
        >
          <FormatBoldIcon />
        </button>
      </Tooltip>
      <Tooltip placement="top" title="Italic">
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          // disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={`menuButton ${editor.isActive('italic') ? 'is-active' : ''}`}
        >
          <FormatItalicIcon />
        </button>
      </Tooltip>
      <Tooltip placement="top" title="Underline">
        <button
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          // disabled={!editor.can().chain().focus().toggleUnderline().run()}
          className={`menuButton ${editor.isActive('underline') ? 'is-active' : ''}`}
        >
          <FormatUnderlinedIcon />
        </button>
      </Tooltip>
      <Tooltip placement="top" title="Strike">
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          // disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={`menuButton ${editor.isActive('strike') ? 'is-active' : ''}`}
        >
          <FormatStrikethroughIcon />
        </button>
      </Tooltip>

      <Tooltip placement="top" title="Header large">
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={`menuButton ${editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}`}
        >
          <TitleIcon fontSize="medium" />
        </button>
      </Tooltip>
      <Tooltip placement="top" title="Header medium">
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          className={`menuButton ${editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}`}
        >
          <TitleIcon fontSize="small" />
        </button>
      </Tooltip>
      <Tooltip placement="top" title="Header small">
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          className={`menuButton ${editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}`}
        >
          <TitleIcon fontSize="xsmall" />
        </button>
      </Tooltip>

      <Tooltip placement="top" title="Bullet list">
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={`menuButton ${editor.isActive('bulletList') ? 'is-active' : ''}`}
        >
          <FormatListBulletedIcon />
        </button>
      </Tooltip>
      <Tooltip placement="top" title="Numbered list">
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={`menuButton ${editor.isActive('orderedList') ? 'is-active' : ''}`}
        >
          <FormatListNumberedIcon />
        </button>
      </Tooltip>

      <Tooltip placement="top" title="Quote">
        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={`menuButton ${editor.isActive('blockquote') ? 'is-active' : ''}`}
        >
          <FormatQuoteIcon />
        </button>
      </Tooltip>
      <Tooltip placement="top" title="Link">
        <button
          onClick={() => editor.chain().focus().toggleLink().run()}
          className={`menuButton ${editor.isActive('link') ? 'is-active' : ''}`}
        >
          <LinkIcon />
        </button>
      </Tooltip>
      <Tooltip placement="top" title="Undo">
        <button
          className={`menuButton`}
          onClick={() => editor.chain().focus().undo().run()}
          // disabled={!editor.can().chain().focus().undo().run()}
        >
          <UndoIcon />
        </button>
      </Tooltip>
      <Tooltip placement="top" title="Redo">
        <button
          className={`menuButton`}
          onClick={() => editor.chain().focus().redo().run()}
          // disabled={!editor.can().chain().focus().redo().run()}
        >
          <RedoIcon />
        </button>
      </Tooltip>
      <Tooltip placement="top" title="Black">
        <button
          onClick={() => editor.chain().focus().setColor('black').run()}
          className={`menuButton ${editor.isActive('textStyle', { color: 'black' }) ? 'is-active' : ''}`}
        >
          <p style={{ minWidth: '16px', minHeight: '16px', backgroundColor: 'black', border: '1px solid black' }}></p>
        </button>
      </Tooltip>

      <Tooltip placement="top" title="Red">
        <button
          onClick={() => editor.chain().focus().setColor('#c20000').run()}
          className={`menuButton ${editor.isActive('textStyle', { color: '#c20000' }) ? 'is-active' : ''}`}
        >
          <p
            style={{ minWidth: '16px', minHeight: '16px', backgroundColor: '#c20000', border: '1px solid #c20000' }}
          ></p>
        </button>
      </Tooltip>
      <Tooltip placement="top" title="Blue">
        <button
          onClick={() => editor.chain().focus().setColor('#1c32f3').run()}
          className={`menuButton ${editor.isActive('textStyle', { color: '#1c32f3' }) ? 'is-active' : ''}`}
        >
          <p
            style={{ minWidth: '16px', minHeight: '16px', backgroundColor: '#1c32f3', border: '1px solid #1c32f3' }}
          ></p>
        </button>
      </Tooltip>

      <Tooltip placement="top" title="Clear all">
        <button
          onClick={() => {
            editor.chain().focus().unsetAllMarks().run();
            editor.chain().focus().clearNodes().run();
          }}
          className={`menuButton`}
        >
          <FormatClearIcon />
        </button>
      </Tooltip>
    </div>
  );
};

const CustomParagraph = Paragraph.extend({
  addAttributes() {
    return {
      id: {
        default: null,
        // Take the attribute values
        parseHTML: (element) => element.getAttribute('id'),
        renderHTML: (attributes) => {
          return {
            id: attributes.id ? attributes.id : null,
          };
        },
      },
    };
  },
});

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    paragraph: false,
  }),
  Underline,
  Link,
  Image,
  CustomParagraph,
];

const Tiptap = ({ emailData, setEmailData, tempData, providerData }) => {
  const [chosenEmail, setChosenEmail] = useState();

  const editor = useEditor({
    extensions: extensions,
    autofocus: false,
    onUpdate({ editor }) {
      console.log(editor.getHTML(), ' HTML');
      //setEmailData(...emailData, (emailData.emailString = editor.getHTML()));
      setEmailData((prevState) => ({ ...prevState, emailString: editor.getHTML() }));
    },
    content: emailData.emailString || '',
  });

  const signatureSet = (signature) => {
    let sign = '';
    switch (signature) {
      case 'Andi':
        sign = `<p id="signature"> <p style='color: #ffbe17'>
          <strong>Környei Andrea</strong>
        </p>
        <p>
          <strong><i>Ügyfélkapcsolati szakértő</i></strong>
        </p>
        <p style='color: #0600ff'>+36 70 637 4655</p>
        <p style='color: #0600ff'>andrea@bookkeepie.com</p>
        <a href='https://www.bookkeepie.hu/' style='color: white'>
          <img
            src='https://k-net-public-images.s3.eu-west-1.amazonaws.com/bookkeepie-logo-sign.png'
            alt='Bookkeepie'
          /></a> </p>`;

        break;
      case 'Nina':
        sign = `<p id="signature"> <p style='color: #ffbe17'>
          <strong>Bodnár Nikoletta Leila</strong>
        </p>
        <p>
          <strong><i>Ügyfélszolgálati szakértő</i></strong>
        </p>
        <p style='color: #0600ff'>+36 20 238 3689</p>
        <p style='color: #0600ff'>nina@bookkeepie.com</p> <a href='https://www.bookkeepie.hu/' style='color: white'>
          <img
            src='https://k-net-public-images.s3.eu-west-1.amazonaws.com/bookkeepie-logo-sign.png'
            alt='Bookkeepie'
          /></a> </p>`;

        break;
      case 'Zsófi':
        sign = `<p id="signature"> 
        <p style='color: #ffbe17'>
          <strong>Molnár Zsófi</strong>
        </p>
        <p>
          <strong><i>Ügyfélszolgálati munkatárs</i></strong>
        </p>
        <p style='color: #0600ff'>+36 20 339 9796</p>
        <p style='color: #0600ff'>zsofi@bookkeepie.com</p> <a href='https://www.bookkeepie.hu/' style='color: white'>
          <img
            src='https://k-net-public-images.s3.eu-west-1.amazonaws.com/bookkeepie-logo-sign.png'
            alt='Bookkeepie'
          /></a> </p>`;
        break;
      case 'Orsi':
        sign = `<p id="signature"> 
        <p style='color: #ffbe17'>
          <strong>Aradványi Orsolya</strong>
        </p>
        <p>
          <strong><i>Ügyfélszolgálati munkatárs</i></strong>
        </p>
        <p style='color: #0600ff'>+36 70 804 1104</p>
        <p style='color: #0600ff'>orsi@bookkeepie.com</p> <a href='https://www.bookkeepie.hu/' style='color: white'>
          <img
            src='https://k-net-public-images.s3.eu-west-1.amazonaws.com/bookkeepie-logo-sign.png'
            alt='Bookkeepie'
          /></a> </p>`;
        break;

      case 'Info':
        sign = `<p id="signature"> 
        <p style='color: #ffbe17'>
          <strong>Bookkeepie International Kft</strong>
        </p>
        <p style='color: #0600ff'>+36 20 252 4740</p>
        <p style='color: #0600ff'>info-hu@bookkeepie.com</p> 
        <a href='https://www.bookkeepie.hu/' style='color: white'>
          <img
            src='https://k-net-public-images.s3.eu-west-1.amazonaws.com/bookkeepie-logo-sign.png'
            alt='Bookkeepie'
          />
        </a> </p>`;

        break;
      case 'Dia':
        sign = `<p id="signature"> 
        <p style='color: #ffbe17'>
          <strong>Turay Dia</strong>
        </p>
        <p>CFO</p>
        <p style='color: #0600ff'>+36 30 329 4314</p>
        <p style='color: #0600ff'>dia@bookkeepie.com</p>
         <a href='https://www.bookkeepie.hu/' style='color: white'>
          <img
            src='https://k-net-public-images.s3.eu-west-1.amazonaws.com/bookkeepie-logo-sign.png'
            alt='Bookkeepie'
          /></a> </p>`;

        break;
      case 'Andris':
        sign = `<p id="signature"> 
        <p style='color: #ffbe17'>
          <strong>Vizsy András</strong>
        </p>
        <p>CEO</p>
        <p style='color: #0600ff'>+36 20 252 4740</p>
        <p style='color: #0600ff'>andras@bookkeepie.com</p>
        <a href='https://www.bookkeepie.hu/' style='color: white'>
          <img
            src='https://k-net-public-images.s3.eu-west-1.amazonaws.com/bookkeepie-logo-sign.png'
            alt='Bookkeepie'
          />
        </a> </p>`;
        break;
      default:
        sign = `<p id="signature"> 
        <p style='color: #ffbe17'>
          <strong>Bookkeepie International Kft</strong>
        </p>
        <p style='color: #0600ff'>+36 20 252 4740</p>
        <p style='color: #0600ff'>info-hu@bookkeepie.com</p> 
        <a href='https://www.bookkeepie.hu/' style='color: white'>
          <img
            src='https://k-net-public-images.s3.eu-west-1.amazonaws.com/bookkeepie-logo-sign.png'
            alt='Bookkeepie'
          />
        </a> </p>`;
    }
    return sign;
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
        <p style={{ color: 'red' }}>Válassz e-mail vázat!</p>
        <Dropdown
          label="E-mail form"
          id="selectedTemplate"
          list={templateList}
          onChange={(value) => {
            editor.commands.setContent(
              getTemplate({ chosenTemp: value, tempData: tempData, providerData: providerData })
            );
            subjectSetter(value);
            setChosenEmail(value);
            for (const [x, label] of Object.entries(templateList)) {
              if (value === label.id) {
                setEmailData({
                  ...emailData,
                  templateShort: label.label,
                  selectedTemplate: label.id,
                  subject: subjectSetter(label.id),
                  emailString: getTemplate({
                    chosenTemp: value,
                    tempData: tempData,
                    providerData: providerData,
                  }),
                });
              }
            }
          }}
          selValue={chosenEmail || ''}
          style={{ width: '200px' }}
          variant="standard"
        ></Dropdown>

        <p style={{ color: 'red' }}>Válassz aláírást:</p>
        <Dropdown
          label="Aláírás"
          id="signature"
          list={signatureList}
          onChange={(value) => {
            //console.log(emailData.emailString, 'STRING');
            setEmailData((prevState) => ({
              ...prevState,
              emailString: emailData.emailString.split(`<p id="signature">`)[0] + signatureSet(value),
              signature: value,
            }));
            //console.log(emailData.emailString.split(`<p id="signature">`)[0], 'ASD');
            editor.commands.setContent(emailData.emailString.split(`<p id="signature">`)[0] + signatureSet(value));
            // editor.commands.setContent(emailData.emailString.replace(regex, signatureSet(value)));
          }}
          selValue={emailData.signature || ''}
          style={{ width: '200px' }}
          variant="standard"
        ></Dropdown>
      </div>
      <MenuBar editor={editor} />
      <EditorContent editor={editor} />
    </div>
  );
};

export default Tiptap;
